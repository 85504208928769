// "Hot Module Replacement" enabled environment

export const environment = {
    production: false,
    hmr: true,
    appConfig: 'appconfig.json',
    appUrl: 'https://qametrikui-homol.qametrik.com/',
    serverUrl:'https://qametrikui-homol.qametrik.com/',
    deployUrl:'https://qametrikui-homol.qametrik.com/',
    clarityUrl: 'https://clarity.microsoft.com/projects/view/mpr9elld2x/dashboard?date=Last%203%20days',
    gitbookUrl: 'https://qametrik.gitbook.io/qametrik-devops/',
    textUml: 'Based on the given text, generate all ABAP code including program interfaces, classes, functions and methods, create the classes using public definition. Create parameters with appropriate types: import, export and return. Create PUBLIC, PROTECTED and PRIVATE sections as required based on the text, for the classes generate in addition to definition also implementation, do not include description of generated code and create table.',
    textTechnicalSpecification: 'Based on the specification functional, generate all ABAP code including or update program interfaces, classes, functions and methods, create the classes using public definition. Create parameters with appropriate types: import, export and return. Create PUBLIC, PROTECTED and PRIVATE sections as required based on the text, for the classes generate in addition to definition also implementation and generation code. If generation code, create a class test ABAP.'
};
